export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'small',
        class: 'forbehold',
        style: 'padding-block: 4.375rem 2.5rem; padding-inline: 1rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.content'),
            },
            props: {
              class: 'forbehold__text',
            },
          },
        ],
      },
    },
  ]
}
