import { filter, transformContent } from '@/utils'

export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'medium',
        style: 'padding-block: 1.875rem;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'text',
              style: 'padding-block: 2.5rem 2.75rem;',
            },
            data: {
              content: transformContent( this.$path('item.content')),
            },
          },
          ...(this.$path('item.media.cover') || []).map(item => {
            return {
              component: 'Image',
              props: {
                class: 'owner__image',
                style: 'padding-block: 0rem 1rem;',
              },
              data: {
                image: item,
              },
            }
          }),
          {
            component: 'Text',
            props: {
              style: 'padding-block: 0rem 1.5rem;',
              class: 'text',
            },
            data: {
              content: this.$path('item.customFields.content'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'medium',
        style: 'padding-block: 0rem 2.875rem;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'owner__content',
            },
            data: {
              content: this.$path('item.customFields.owner.content'),
            },
          },
          {
            component: 'Text',
            props: { class: 'owner__name' },
            data: {
              content: this.$path('item.customFields.owner.name'),
            },
          },
          {
            component: 'Text',
            props: { class: 'owner__title' },
            data: {
              content: this.$path('item.customFields.owner.title'),
            },
          },
          {
            component: 'Text',
            props: { class: 'owner__phone' },
            data: {
              content: this.$path('item.customFields.owner.phone'),
            },
          },
          {
            component: 'Link',
            props: {
              class: 'owner__email',
              label: this.$path('item.customFields.owner.email'),
              target: '_self',
            },
            data: {
              href: `mailto:${this.$path('item.customFields.owner.email')}`,
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: this.$path('item.customFields.call-to-action'),
      props: {
        width: 'medium',
        class: '',
        style: 'padding-block: 1.875rem 1.875rem;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.call-to-action'),
            },
            props: {
              class: 'call-to-action__text',
              textAlign: 'center',
            },
          },
        ],
      },
    },
  ].filter(f => filter(f))
}
