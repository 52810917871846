<template>
  <div class="read-more">
    <span class="read-more__content">Les Mer</span>
    <FontAwesomeIcon :icon="['fas', 'chevron-down']" size="1x" class="read-more__icon" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.read-more {
  color: white !important;
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
  @include header-font(1.25rem);
  &__content {
    font-weight: 800;
    text-transform: uppercase;
  }
  &__icon {
    margin-top: 0.5em;
  }
  &:hover {
    text-decoration: underline;
  }
}
</style>
