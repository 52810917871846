<template>
  <div>
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </div>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/subpages/facilities.js'

import postComponents from '@/components/Post'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this)
    },
    customComponents() {
      return { ...postComponents }
    },
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss"></style>
