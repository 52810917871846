import { filter, transformContent } from '@/utils'

export default function() {
  return [
    {
      component: 'Section',
      condition: this.$path('item.content'),
      props: {
        width: 'medium',
        style: 'padding-block: 1.875rem 1.875rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: transformContent(this.$path('item.content')),
            },
            props: {
              class: 'text',
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: () => {
        if (!this.$path('item.residentials')) return false
        return this.$path('item.residentials').length
      },
      props: {
        width: 'medium',
        style: 'padding-block: 1.875rem 1.875rem',
      },
      blocks: {
        default: [
          {
            component: 'Residentials',
            data: {},

            props: {
              value: this.$path('item.residentials'),
              class: '',
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: () => {
        if (!this.$path('item.customFields.plans')) return false
        return this.$path('item.customFields.plans').filter(f => f.image).length
      },
      props: {
        width: 'medium',
        class: 'plans',
        style: 'padding-block: 1.875rem 1.875rem',
      },
      blocks: {
        default: [
          {
            component: 'Grid',
            props: {
              class: 'plans__layout',
            },

            blocks: {
              default: this.$path('item.customFields.plans')
                .map(item => {
                  return [
                    {
                      component: 'Gallery',
                      props: {
                        columns: 1,
                        class: 'plans__image',
                      },
                      variant: 'grid',
                      data: {
                        images: [item.image],
                      },
                    },
                  ]
                })
                .flat(),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: this.$path('item.customFields.call-to-action'),
      props: {
        width: 'medium',
        class: 'call-to-action',
        style: 'padding-block: 1.875rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.call-to-action'),
            },
            props: {
              class: 'call-to-action__text call-to-action__text--areas',
              textAlign: 'center',
            },
          },
        ],
      },
    },
  ].filter(f => filter(f))
}
