import { filter } from '@/utils'

export default function() {
  return [
    {
      component: 'Banner',
      condition: this.$path('item.media.cover.0.url'),
      data: {
        backgroundImage: this.$path('item.media.cover.0.url'),
      },
      props: {
        class: 'banner',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            condition: this.$path('item.content'),
            data: {
              content: this.$path('item.content'),
            },
            props: {
              class: 'banner__figcaption banner__figcaption--office',
            },
          },
        ].filter(f => filter(f)),
      },
    },
    {
      component: 'Section',
      condition: this.$path('item.content'),
      props: {
        style: 'padding-block: 0rem; padding-inline: 0rem; --section-padding-inline: 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',

            data: {
              content: this.$path('item.content'),
            },
            props: {
              class: 'banner__figcaption--mobile',
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',

        style: 'padding-block: 0rem 2.625rem;',
      },
      blocks: {
        default: {
          component: 'Section',
          props: {
            width: 'full',
            class: 'border-line',
            style: 'padding-block: 0rem;',
          },
        },
      },
    },

    ...(this.$path('item.customFields.section') || []).map(item => {
      return {
        component: 'Section',
        condition: item.image,
        props: {
          width: item['image-layout'] ? item['image-layout'] : 'medium',
          style:
            item['image-layout'] === 'full' ? 'padding-block: 3.125rem' : 'padding-block: 1rem',
        },
        blocks: {
          default: [
            {
              component: 'Gallery',
              props: {
                columns: 1,
              },
              variant: 'grid',
              data: {
                images: [item.image],
              },
            },
          ],
        },
      }
    }),

    {
      component: 'Section',
      condition: this.$path('item.customFields.call-to-action'),
      props: {
        width: 'medium',
        class: 'call-to-action',
        style: 'padding-block: 0rem 2.875rem ',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.call-to-action'),
            },
            props: {
              class: 'call-to-action__text',
              textAlign: 'center',
            },
          },
        ],
      },
    },
  ].filter(f => filter(f))
}
