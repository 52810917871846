<template>
  <Loader :value="promises.fetch" class="post">
    <component v-if="component" :is="component" :item="post" :key="post.id" />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Types from './Types'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Post', ['promises', 'item']),

    post() {
      if (!this.item.posts) return {}
      let post = this.item.posts.find(p => p.slug === this.slug)

      // attach call to action if not defined
      if (
        !post.customFields['call-to-action'] ||
        post.customFields['call-to-action'] === '<p></p>'
      ) {
        post.customFields['call-to-action'] = this.item.customFields['call-to-action']
      }

      // attach articles if selected
      if (post.customFields['article-cards-checked']) {
        let articles = this.item.posts.filter(item => Boolean(item.customFields['is-article']))
        //sort articles
        articles.sort((a, b) => a.customFields.order - b.customFields.order)
        post.articles = articles
      }

      // attach address if selected
      if (post.customFields['show-address-map']) {
        post.address = this.item.address
      }

      // attach address & residentials
      post.residentials = this.item.residentials

      post.address = this.item.address

      return post
    },

    component() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || []
      let key = (sources[0] || '').replace('lysakertorg-', '')

      switch (key) {
        case 'office':
          return Types.Office
        case 'owner':
          return Types.Owner
        case 'location':
          return Types.Location
        case 'areas':
          return Types.Areas
        case 'facilities':
          return Types.Facilities

        case 'disclaimer':
          return Types.Disclaimer
        default:
          return Types.Default
      }
    },
  },
  methods: {
    ...mapActions('Post', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    ...Types,
  },
}
</script>

<style lang="scss">
.post {
  @keyframes PageTransition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .border-line {
    background: var(--primary);
    height: 15px;
  }

  .banner {
    height: 80vh;
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--darken {
      background: rgba(0, 0, 0, 0.25);
      width: 100%;
      height: 100%;
    }

    &__figcaption {
      padding: 30px;
      line-height: 28px;
      background: var(--beige);
      color: var(--black);
      display: -moz-flex;
      display: flex;
      flex-direction: column;

      width: 30%;
      @media (max-width: 991px) {
        width: 70%;
      }
      margin-left: 7%;

      @include respond-below('phone') {
        display: none;
      }

      h2 {
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
      p {
        line-height: 1.5;
        font-weight: 400;
        &:first-of-type {
          margin-top: 0;
        }
      }

      &--office {
        h2 {
          margin-bottom: 1rem;
          text-align: center;
        }
        p {
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
          &:last-of-type {
            margin-bottom: 1rem;
          }
        }
      }

      &--facilities {
        h2 {
          margin-bottom: 1rem;
        }
        p {
          margin-top: 0rem;
          margin-bottom: 1rem;
        }
      }

      &--mobile {
        padding: 1.25rem;
        @include respond-above('phone') {
          display: none;
        }
        h2 {
          font-size: 1.75rem;
          margin-bottom: 0.5rem;
          font-weight: 500;
        }
        p {
          line-height: 1.5;
          font-weight: 400;
          font-size: 1rem;
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }

  .owner {
    &__image {
      align-items: center;
      .kpb-image__image {
        width: 280px;
        height: 53px;
        object-fit: contain;
        background-color: inherit;
      }
    }

    &__content {
      line-height: 1.5;
    }
    &__name {
      line-height: 1.5;
      font-weight: bold;
    }
    &__title {
      line-height: 1.5;
    }
    &__phone {
      line-height: 1.5;
    }
    &__email {
      line-height: 1.5;
      color: var(--link);
      text-decoration: none;
      &:hover {
        color: var(--link-hover);
        text-decoration: underline;
      }
    }
  }

  .plans {
    --padding: 1.25rem;
    &__layout {
      grid-template-columns: 1fr !important;
      gap: 2rem !important;

      @include respond-below('phone') {
        grid-template-columns: 1fr !important;
      }
    }

    &__content {
      background-color: var(--light-grey);
      padding: var(--padding);

      @include header-font(1rem);
      color: var(--grey);
      h1,
      h2 {
        @include header-font(1rem);
        font-weight: 900;
        margin-bottom: 1.125rem;
      }

      p {
        text-decoration: underline;
        margin-bottom: 2rem;
      }
      ul {
        list-style-type: none;
        padding-inline-start: 0;
        li {
          p {
            text-align: justify;
            line-height: 0.75;
            &:after {
              content: '';

              width: 100%;
              height: 0px;
              display: inline-block;
            }
            margin: 0;
            padding: 0;
            margin-block-start: 0em;
            margin-block-end: 0em;
            text-decoration: none;
            margin-bottom: 0rem;
          }
        }
      }

      p:has(strong) {
        &:first-of-type {
          margin-block-start: 2rem;
        }

        &:not(:first-of-type) {
          margin-block-end: 2rem;
        }
      }
    }

    &__image {
      --kpb-column-gap: 0rem !important;

      max-width: 100%;

      .kpb-gallery__item {
        max-width: 100%;
        &-thumbnail {
          object-fit: contain;
          max-width: 100%;
        }
      }
    }
  }

  .features {
    &__kicker {
      font-weight: 500;
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    flex-wrap: wrap !important;
    align-items: flex-start !important;
    gap: 0rem !important;

    //overide kbp flex
    @media (max-width: 600px) {
      flex-direction: row;
    }

    &__feature {
      flex: 0 0 50%;
      max-width: 50%;
      justify-content: center !important;
      gap: 0rem !important;

      padding-right: 15px;
      padding-left: 15px;

      @media (min-width: 576px) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }

      @include respond-above('phone') {
        flex: 0 0 25%;
        max-width: 25%;
      }

      @include respond-above('tablet') {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
      }
    }
    &__icon {
      justify-content: center !important;
      align-content: center !important;
      align-items: center !important;

      color: var(--red);
      background: inherit;

      --size: 8.438rem;

      font-size: 1rem;

      .kpb-image__image {
        width: 150px;
        height: 120px;

        object-fit: contain;
        background-color: unset;
      }
    }
    &__title {
      color: var(--feature-black);
      text-align: center;

      word-break: break-word;
      @include header-font(1.4rem);
      margin-bottom: 0rem;

      &--facilities {
        font-weight: 100;
        @include header-font(1.1rem);
      }
    }
    &__subtitle {
      color: var(--feature-black);
      text-align: center;
      font-weight: 100;
      @include header-font(1.1rem);
      word-break: break-word;

      margin-bottom: 1rem;
      &--facilities {
        @include header-font(0.8rem);
      }
    }
  }

  .text {
    color: var(--black);

    line-height: 1.5;
    h1,
    h2 {
      margin-bottom: 0.5rem;
      text-align: center;
      font-weight: 400;
      @include header-font(2.2rem);
    }
  }

  .kpb-map__mapbox {
    max-height: 530px;
    height: 70%;
    width: 100%;
  }

  .call-to-action {
    color: var(--black);

    &__text {
      h2 {
        font-weight: 500;
        @include header-font(1.75rem);
        margin-bottom: 0.5rem;
      }
      p {
        margin-top: 0rem;
      }

      &--facilities {
        h2 {
          @include header-font(2rem);
        }
      }
      &--areas {
        h2 {
          @include header-font(2.2rem);
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>
