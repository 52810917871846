import { filter } from '@/utils'

export default function() {
  return [
    {
      component: 'Banner',
      condition: this.$path('item.media.cover.0.url'),
      data: {
        backgroundImage: this.$path('item.media.cover.0.url'),
      },
      props: {
        class: 'banner',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            condition: this.$path('item.content'),
            data: {
              content: this.$path('item.content'),
            },
            props: {
              class: 'banner__figcaption',
            },
          },
        ].filter(f => filter(f)),
      },
    },

    {
      component: 'Section',
      condition: this.$path('item.content'),
      props: {
        style: 'padding-block: 0rem; padding-inline: 0rem; --section-padding-inline: 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',

            data: {
              content: this.$path('item.content'),
            },
            props: {
              class: 'banner__figcaption--mobile',
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: () => {
        if (!this.$path('item.customFields.features')) return false
        return this.$path('item.customFields.features').filter(f => f.icon || f.title || f.subtitle)
          .length
      },
      props: {
        width: 'medium',
        style: 'padding-block: 3.125rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              textAlign: 'center',
              class: 'features__kicker',
            },
            data: {
              content: this.$path('item.customFields.kicker'),
            },
          },
          {
            component: 'Flex',
            props: {
              class: 'features',
            },
            blocks: {
              default: (this.$path('item.customFields.features') || []).map((item, index) => {
                return {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0rem',
                    class: 'features__feature',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Image',
                        data: {
                          image: item.icon,
                        },
                        props: {
                          class: 'features__icon',
                        },
                      },
                      {
                        component: 'Text',
                        data: {
                          content: item.title,
                        },

                        props: {
                          class: 'features__title',
                        },
                      },
                      {
                        component: 'Text',
                        data: {
                          content: item.subtitle,
                        },

                        props: {
                          class: 'features__subtitle',
                        },
                      },
                    ],
                  },
                }
              }),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: this.$path('item.customFields.image'),

      props: {
        width: 'medium',
        style: 'padding-block: 4.688rem',
      },
      blocks: {
        default: [
          {
            component: 'Gallery',
            props: {
              columns: 1,
            },
            data: {
              images: [this.$path('item.customFields.image')],
            },
          },
        ],
      },
    },

    ...(this.$path('item.customFields.gallery') || []).map(item => {
      return {
        component: 'Section',
        condition: item.gallery.length,
        props: {
          width: 'full',
          style: 'padding-block: 1.563rem',
        },
        blocks: {
          default: [
            {
              component: 'Gallery',
              props: {
                columns: item.gallery.length > 1 ? 2 : 1,
              },
              variant: 'grid',
              data: {
                images: item.gallery,
              },
            },
          ],
        },
      }
    }),

    {
      component: 'Section',
      condition: this.$path('item.customFields.site-settings.show-map'),

      props: {
        width: 'full',
        style: 'padding-block: 0.313rem 1.875rem;',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            props: {
              class: 'location-map',
              showControls: true,
              mapOptions: {
                dragPan: true,
                zoom: 16,
              },
            },
            data: {
              coordinates: this.$path('item.address.location.coordinates'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: this.$path('item.customFields.call-to-action'),
      props: {
        width: 'medium',
        class: 'call-to-action',
        style: 'padding-block: 1.875rem 2.875rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.call-to-action'),
            },
            props: {
              class: 'call-to-action__text',
              textAlign: 'center',
            },
          },
        ],
      },
    },
  ].filter(f => filter(f))
}
