<template>
  <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/consent/disclaimer.js'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this)
    },
    customComponents() {
      return {}
    },
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.forbehold {
  &__text {
    li::marker {
      color: initial;
    }
    h2 {
      font-size: 2.375rem;
      margin-bottom: 2rem;
      font-weight: 400;
    }
  }
}
</style>
